import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Context from '~/context/DataContext';

const DataProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query StaticQuery {
      shopify {
        tProducts: translatableResources(resourceType: PRODUCT, first: 200) {
          edges {
            node {
              resourceId
              translations(locale: "en") {
                key
                locale
                value
              }
            }
          }
        }
        tCollections: translatableResources(
          resourceType: COLLECTION
          first: 30
        ) {
          edges {
            node {
              resourceId
              translations(locale: "en") {
                locale
                value
                key
              }
            }
          }
        }
      }
      collections: allShopifyCollection(sort: { title: ASC }) {
        nodes {
          handle
          title
          shopifyId
        }
      }
    }
  `);

  return (
    <Context.Provider
      value={{
        translations: {
          products: data.shopify.tProducts.edges,
          collections: data.shopify.tCollections.edges,
        },
        collectionLinks: data.collections.nodes,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default DataProvider;
