// Check browser
export const isBrowser = () => typeof window !== 'undefined';

// Get user
export const getUser = () =>
  isBrowser() && window.localStorage.getItem('user')
    ? JSON.parse(window.localStorage.getItem('user'))
    : null;

// Set user
export const setUser = (user) =>
  window.localStorage.setItem('user', JSON.stringify(user));

// Logout
export const logout = (callback) => {
  setUser({});
  callback();
};

// Check if authenticated
export const checkAuth = () => {
  const user = getUser();
  return Boolean(user && user.username);
};
