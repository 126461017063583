import React, { useState, useEffect } from 'react';
import Client from 'shopify-buy';

import Context from '~/context/StoreContext';

// Shopify credentials
const client = Client.buildClient({
  storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
  domain: `checkout.${process.env.SHOP_NAME}.se`,
  language: 'sv-SE',
});

const ContextProvider = ({ children }) => {
  // Initials state
  const initialStoreState = {
    client,
    isLoading: false,
    lastAdd: null,
    checkout: {
      lineItems: [],
    },
    products: [],
    shop: {},
  };

  // State
  const [store, updateStore] = useState(initialStoreState);

  useEffect(() => {
    // Initialize Checkout
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== 'undefined';

      // Existing checkout
      const existingCheckoutID = isBrowser
        ? localStorage.getItem('shopify_checkout_id')
        : null;

      // Checkout
      const setCheckoutInState = (checkout) => {
        if (isBrowser) {
          localStorage.setItem('shopify_checkout_id', checkout.id);
        }

        updateStore((prevState) => ({ ...prevState, checkout }));
      };
      const createNewCheckout = () => store.client.checkout.create();
      const fetchCheckout = (id) => store.client.checkout.fetch(id);

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID);
          // Make sure this cart hasn’t already been purchased.
          if (!checkout.completedAt) {
            setCheckoutInState(checkout);
            return;
          }
        } catch (e) {
          localStorage.setItem('shopify_checkout_id', null);
        }
      }
      const newCheckout = await createNewCheckout();
      setCheckoutInState(newCheckout);
    };

    initializeCheckout();
  }, [store.client.checkout]);

  return (
    <Context.Provider
      value={{
        store,
        addVariantToCart: async (variantId, quantity) => {
          if (variantId === '' || !quantity) {
            console.error('Both a size and quantity are required.');
            return;
          }

          const lineItemsToUpdate = [
            { variantId, quantity: parseInt(quantity, 10) },
          ];

          updateStore((prevState) => ({ ...prevState, isLoading: true }));

          const checkout = await store.client.checkout.addLineItems(
            store.checkout.id,
            lineItemsToUpdate
          );

          updateStore((prevState) => ({
            ...prevState,
            checkout,
            isLoading: false,
            lastAdd: Date.now(),
          }));
        },
        removeLineItem: async (lineItemId) => {
          updateStore((prevState) => ({ ...prevState, isLoading: true }));

          const checkout = await store.client.checkout.removeLineItems(
            store.checkout.id,
            [lineItemId]
          );

          updateStore((prevState) => ({
            ...prevState,
            checkout,
            isLoading: false,
          }));
        },
        updateLineItem: async (lineItemId, quantity) => {
          const lineItemsToUpdate = [
            { id: lineItemId, quantity: parseInt(quantity, 10) },
          ];

          updateStore((prevState) => ({ ...prevState, isLoading: true }));

          const checkout = await store.client.checkout.updateLineItems(
            store.checkout.id,
            lineItemsToUpdate
          );

          updateStore((prevState) => ({
            ...prevState,
            checkout,
            isLoading: false,
          }));
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default ContextProvider;
