import React from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { GatsbyImage } from 'gatsby-plugin-image';

export const theme = {
  fonts: {
    primary: "'Prata', serif",
    secondary: "'Roboto', sans-serif",
  },
  colors: {
    primary: '#000000',
    background: '#000000',
    dark: '#000000',
    light: '#FFFFFF',
  },
};

// Breakpoints
export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
};

// Global style
export const GlobalStyle = (props) => (
  <Global
    {...props}
    styles={css`
      body {
        margin: 0;
      }
      input {
        -webkit-appearance: none;
      }
      input[type='checkbox'] {
        -webkit-appearance: checkbox;
      }
      html {
        font-family: ${theme.fonts.secondary};
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: ${theme.fonts.primary};
      }
      p {
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
        line-height: 24px;
      }
      a[href^='tel'] {
        color: inherit;
        text-decoration: none;
      }
    `}
  />
);

// Image
export const Img = styled(GatsbyImage)`
  margin: 0 0 8px;
  padding: 0;
`;

export const PageImage = styled(GatsbyImage)`
  margin: 24px 0;
`;

// Wrapper
export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 24px 0 48px;

  @media (max-width: ${breakpoints.xl}px) {
    padding: 0 24px;
  }

  @media (max-width: ${breakpoints.l}px) {
    padding: 0 16px;
  }
`;

// Container
export const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
`;

// Two sections
export const TwoSectionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ gap }) => (gap ? '24px' : '0')};
  margin-bottom: ${({ gap }) => (gap ? '24px' : '0')};

  @media (max-width: ${breakpoints.l}px) {
    grid-template-columns: repeat(1, 1fr);
    gap: ${({ gap }) => (gap ? '16px' : '0')};
    margin-bottom: ${({ gap }) => (gap ? '16px' : '0')};
  }
`;

// Content
export const Content = styled.main`
  margin-bottom: 64px;

  @media (max-width: ${breakpoints.l}px) {
    margin-bottom: 32px;
  }
`;

export const Text = styled.p`
  padding: 8px 0;
  margin: 0;
`;
