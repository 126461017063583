exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contentful-page-slug-jsx": () => import("./../../../src/pages/{ContentfulPage.slug}.jsx" /* webpackChunkName: "component---src-pages-contentful-page-slug-jsx" */),
  "component---src-pages-erbjudanden-jsx": () => import("./../../../src/pages/erbjudanden.jsx" /* webpackChunkName: "component---src-pages-erbjudanden-jsx" */),
  "component---src-pages-formgivare-index-jsx": () => import("./../../../src/pages/formgivare/index.jsx" /* webpackChunkName: "component---src-pages-formgivare-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nyheter-contentful-article-slug-jsx": () => import("./../../../src/pages/nyheter/{ContentfulArticle.slug}.jsx" /* webpackChunkName: "component---src-pages-nyheter-contentful-article-slug-jsx" */),
  "component---src-pages-nyheter-index-jsx": () => import("./../../../src/pages/nyheter/index.jsx" /* webpackChunkName: "component---src-pages-nyheter-index-jsx" */),
  "component---src-pages-produkt-shopify-product-handle-jsx": () => import("./../../../src/pages/produkt/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-produkt-shopify-product-handle-jsx" */),
  "component---src-pages-produkter-index-jsx": () => import("./../../../src/pages/produkter/index.jsx" /* webpackChunkName: "component---src-pages-produkter-index-jsx" */),
  "component---src-pages-produkter-shopify-collection-handle-jsx": () => import("./../../../src/pages/produkter/{ShopifyCollection.handle}.jsx" /* webpackChunkName: "component---src-pages-produkter-shopify-collection-handle-jsx" */),
  "component---src-pages-samarbeten-contentful-collaboration-slug-jsx": () => import("./../../../src/pages/samarbeten/{ContentfulCollaboration.slug}.jsx" /* webpackChunkName: "component---src-pages-samarbeten-contentful-collaboration-slug-jsx" */),
  "component---src-pages-samarbeten-index-jsx": () => import("./../../../src/pages/samarbeten/index.jsx" /* webpackChunkName: "component---src-pages-samarbeten-index-jsx" */),
  "component---src-templates-formgivare-jsx": () => import("./../../../src/templates/formgivare.jsx" /* webpackChunkName: "component---src-templates-formgivare-jsx" */)
}

