/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { setUser } from './src/utils/auth';
import config from './src/aws-exports';
Amplify.configure(config);

export const onRouteUpdate = async (state, page, pages) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    setUser({
      ...user.attributes,
      username: user.username
    });
  } catch (error) {
    window.localStorage.setItem('user', null);
  }
}

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
export async function onClientEntry() {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}