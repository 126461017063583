import React, { useEffect, useState } from 'react';
import Context from '~/context/ConsentContext';
import moment from 'moment';

const ConsentProvider = ({ children }) => {
  // Initial state
  const initialConsentState = {
    init: false,
    handled: false,
    accepted: false,
    date: null,
  };

  // State
  const [consent, setConsent] = useState(initialConsentState);

  // Initial
  useEffect(() => {
    const stored = localStorage.getItem('userConsent');
    setConsent((prev) => ({ ...prev, init: true }));
    if (stored) {
      const parsed = JSON.parse(stored);
      const diff = moment(moment(parsed.date)).diff(moment(), 'days');
      if (diff > 30) {
        localStorage.removeItem('userConsent');
      } else {
        setConsent({ ...parsed, init: true, handled: parsed.accepted });
      }
    }
  }, []);

  return (
    <Context.Provider
      value={{
        consent,
        updateConsent: (accept) => {
          const newConsent = { accepted: accept || false, date: Date.now() };
          localStorage.setItem('userConsent', JSON.stringify(newConsent));
          setConsent({ ...newConsent, handled: true });
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ConsentProvider;
