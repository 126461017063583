/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id:
    'eu-central-1:d753a9b0-e08a-442c-92e4-84f5e0f95371',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_BdFN6EoJ3',
  aws_user_pools_web_client_id: '6dnqf98sgeevissaum1me9drne',
  oauth: {},
};

export default awsmobile;
